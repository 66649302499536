import { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { formatDateTime } from "utils/converSelectedDateToString";
import ButtonCircle from "shared/Button/ButtonCircle";
import { CarsListing } from "models/CarsListingModel";
import { Extras, ExtrasOptions } from "models/ExtrasModel";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  cancelBooking,
  cancelBooking as cancelBookingAPI,
} from "api/booking/booking"; // Renamed import here

import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
  DialogTitle,
  Button,
} from "@headlessui/react";
import { useData } from "data/data-provider";
import { retriveBooking } from "api/booking/booking";
import toast from "react-hot-toast";
import UploadZone from "components/upload/upload";
import { globalJson } from "global/global_json";
import { encodeBase64 } from "@progress/kendo-file-saver";
import { sendRentalAgreement } from "api/rental-agreement/rental-agreement";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { formatDateToHumanFirendly } from "utils/date-format";

export interface BookingDetailsPageProps {
  className?: string;
}

const BookingDetailsPage: FC<BookingDetailsPageProps> = ({
  className = "",
}) => {
  const { user, startDate, endDate } = useData();
  const { bookingId } = useParams();

  const [loading, setLoading] = useState(false);

  const [selectedBookingExtras, setSelectedBookingExtras] = useState<
    Map<string, Extras>
  >(new Map());
  const [selectedBookingCar, setSelectedBookingCar] =
    useState<CarsListing | null>(null);
  const [
    selectedBookingInsuranceProtection,
    setSelectedBookingInsuranceProtection,
  ] = useState<ExtrasOptions[]>([]);
  const navigate = useNavigate();

  let [isOpen, setIsOpen] = useState(false);
  let [bookingDetail, setBookingDetail] = useState<any | null>(null);
  const [isPreTrip, setIsPreTrip] = useState<boolean>(true);

  const params = useParams();
  const modifyBooking = async (bookingId: string) => {
    navigate(`/modify-booking/${bookingId}`);
    return;
  };

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }
  const handleCancelBooking = async (bookingId: string) => {
    setLoading(true);
    try {
      // Call the cancelBooking API and check for errors
      const { data, error } = await cancelBooking(bookingId);

      if (error) {
        // Show the specific error returned from the backend
        toast.error(`Error cancelling booking: ${error}`);
      } else {
        // If the cancellation is successful, show success message
        toast.success("Booking cancelled successfully.");
        navigate("/account-bookings"); // Refresh bookings after cancel
      }
    } catch (err) {
      // Catch any unexpected errors
      toast.error("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const handleBeforeUnload = (e: BeforeUnloadEvent) => {
  //     e.preventDefault();
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const openRentalAgreement = async () => {
    if (user && user.email && params?.bookingId) {
      const encodedData = encodeBase64(user?.email);
      const path = `${params.bookingId}b6cr4${
        process.env.REACT_APP_ENV !== "prod"
          ? globalJson?.dev_company_id
          : globalJson?.prod_company_id
      }b6cr4${encodedData}`;

      // Open the URL in a new tab
      window.open(`https://1now.app/pdf?agreement=${path}`, "_blank");
    }
  };

  const sendAgreementEmail = async (type: string) => {
    const encodedData = encodeBase64(user?.email as string); // Encoding data to UTF-8 first

    const path = `${params.bookingId}b6cr4${
      process.env.REACT_APP_ENV !== "prod"
        ? globalJson.dev_company_id
        : globalJson.prod_company_id
    }b6cr4${encodedData}`;
    try {
      const data: any = await sendRentalAgreement(path, type);
      toast.success("Email successfully sent!");
    } catch (error: any) {
      toast.error(error.toString()); // Or handle errors in a more sophisticated way
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchBookingDetails = async () => {
      setLoading(true);
      if (!params?.bookingId) {
        toast.error("Booking ID is missing");
        return;
      }

      try {
        const data = await retriveBooking(params.bookingId);
        setBookingDetail(data);
        setLoading(false);
      } catch (error) {
        toast.error("Error fetching bookings");
        setLoading(false);
      }
    };

    fetchBookingDetails(); // Call the async function
  }, [params]);

  console.log(bookingDetail);
  const parseDateString = (dateString: string): Date => {
    // Split the date and time components
    const [datePart, timePart] = dateString.split(" ");

    // Split the date into year, month, and day
    const [year, month, day] = datePart.split("-").map(Number);

    // Split the time into hours, minutes, and seconds
    const [hours, minutes, seconds] = timePart.split(":").map(Number);

    // Create a new Date object with the parsed values
    const date = new Date(year, month - 1, day, hours, minutes, seconds);

    return date;
  };

  // useEffect(() => {
  //   setSelectedInsuranceProtection([]);
  //   setSelectedBonzahProduct([]);

  //   if (bookingDetail !== null && ourFleet !== null && extras.length > 0) {
  //     let dropOffLocationChanged: boolean = false;
  //     let bonzahOptionsSelectedInBooking: ExtrasOptions[] = [];
  //     console.log(bookingDetail);
  //     let startDateFromString = parseDateString(
  //       bookingDetail.pick_up_time
  //     );
  //     let endDateFromString = parseDateString(bookingDetail.drop_off_time);

  //     setStartDate(startDateFromString);

  //     setStartHours(
  //       startDateFromString.getHours() > 12
  //         ? (startDateFromString.getHours() % 12 < 10 ? "0" : "") +
  //             (startDateFromString.getHours() % 12).toString()
  //         : (startDateFromString.getHours() < 10 ? "0" : "") +
  //             startDateFromString.getHours().toString()
  //     );
  //     setStartMinutes(
  //       startDateFromString.getMinutes() < 10
  //         ? "0" + startDateFromString.getMinutes().toString()
  //         : startDateFromString.getMinutes().toString()
  //     );
  //     setStartPeriod(startDateFromString.getHours() > 12 ? "AM" : "PM");

  //     setStartBookingDate(startDateFromString);
  //     setEndDate(endDateFromString);
  //     setEndHours(
  //       endDateFromString.getHours() > 12
  //         ? (endDateFromString.getHours() % 12 < 10 ? "0" : "") +
  //             (endDateFromString.getHours() % 12).toString()
  //         : (endDateFromString.getHours() < 10 ? "0" : "") +
  //             endDateFromString.getHours().toString()
  //     );
  //     setEndMinutes(
  //       endDateFromString.getMinutes() < 10
  //         ? "0" + endDateFromString.getMinutes().toString()
  //         : endDateFromString.getMinutes().toString()
  //     );
  //     setEndPeriod(endDateFromString.getHours() > 12 ? "AM" : "PM");
  //     setEndBookingDate(endBookingDate);

  //     for (let vehicle of ourFleet?.listings ?? []) {
  //       if (bookingDetail.booking.l_id === vehicle.l_id) {
  //         setSelectedCar(vehicle);
  //       }
  //     }

  //     setDeposit(bookingDetail.booking.order2.deposit_paid_in_cents / 100);
  //     if (bookingDetail.booking.order2.deposit_value > 0) {
  //       setShowDeposit(true);
  //     }
  //     for (let orderItem of bookingDetail?.booking.order.lines ?? []) {
  //       if (orderItem.item_type === "vehicle") {
  //       } else if (orderItem.item_type === "required_extra") {
  //         if (orderItem.title.includes("Bonzah")) {
  //           for (let insuranceOption of insuranceProtection?.options ?? []) {
  //             if ("Bonzah - " + insuranceOption.title === orderItem.title) {
  //               bonzahOptionsSelectedInBooking.push(insuranceOption);
  //             }
  //           }
  //         } else if (orderItem.title.includes("Locations")) {
  //           dropOffLocationChanged = true;
  //           for (let location of companyData?.company.delivery_locations ??
  //             []) {
  //             if ("Locations - " + location.name === orderItem.title) {
  //               setDropOffLocation(location);
  //               setSearchedDropoffLocation(location.name ?? "");
  //             }
  //           }
  //           dropOffLocationExtras?.options.map((location) => {
  //             if ("Locations - " + location.title === orderItem.title) {
  //               setSelectedDropOffLocationExtra(location);
  //             }
  //           });
  //         }
  //       } else if (orderItem.item_type === "extra") {
  //         for (let extra of extras ?? []) {
  //           if (extra.uuid === orderItem.item_id) {
  //             setSelectedExtras((prevExtras) => {
  //               console.log("foundExtra");
  //               const newExtras = new Map(prevExtras); // Create a copy to avoid mutation
  //               newExtras.set(extra.uuid, extra); // Add the extra if selected
  //               return newExtras as Map<string, Extras>;
  //             });
  //           }
  //         }
  //       } else if (orderItem.item_type === "delivery") {
  //         for (let location of companyData?.company.delivery_locations ?? []) {
  //           if (location.name === orderItem.title) {
  //             setPickupLocation(location);
  //             setSearchedPickupLocation(location.name);
  //           }
  //         }
  //       }
  //     }
  //     if (bookingDetail.booking.order2.coupon_id)
  //       callCouponAPI(bookingDetail.booking.order2.coupon_id);
  //     if (dropOffLocationChanged === false) {
  //       setDropOffLocation(pickupLocation);
  //     }
  //     if (bonzahOptionsSelectedInBooking.length > 0) {
  //       setSelectedInsuranceProtection([...bonzahOptionsSelectedInBooking]);
  //     }
  //     // Set a timeout to stop loading after 2 seconds
  //     const timer = setTimeout(() => {
  //       setLoadingBookingDetails(false);
  //     }, 2000);

  //     // Clean up the timer when the component unmounts
  //     return () => clearTimeout(timer);
  //   }
  // }, [bookingDetail, ourFleet, extras]);

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col space-y-8 ">
        <h2 className="text-3xl font-semibold">Booking Details</h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}

        <div className="space-y-6">
          <div className="flex flex-col justify-start sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-44 mr-8">
              <div className=" aspect-w-7 aspect-h-3 sm:aspect-h-7 rounded-2xl overflow-hidden">
                <img
                  alt=""
                  className="absolute inset-0 object-cover w-full"
                  sizes="300px"
                  src={
                    (bookingDetail?.fleet?.fleet_photos?.length ?? 0) > 0
                      ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${bookingDetail.fleet?.fleet_photos[0].photo_url}`
                      : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
                  }
                />
              </div>
            </div>
            <div className="text-[#9AA3AD]">
              Car
              <h2 className="text-xl sm:text-xl lg:text-xl font-semibold items-center mb-2 text-black">
                {bookingDetail?.fleet?.name}
              </h2>
              License plate number
              <h2 className="text-xl sm:text-xl lg:text-xl font-semibold items-center mb-2 text-black">
                {bookingDetail?.fleet?.plate_number}
              </h2>
              {bookingDetail?.trip_status !== "cancelled" && (
                <ButtonPrimary
                  className="bg-red-600 hover:bg-red-600"
                  onClick={() => {
                    handleCancelBooking(bookingDetail.id);
                  }}
                >
                  Cancel Booking
                </ButtonPrimary>
              )}
            </div>
          </div>

          <div className="flex justify-between">
            <div className="gap-2">
              Pick Up
              <span className="mt-1.5 text-lg font-semibold line-clamp-1">
                {formatDateToHumanFirendly(
                  bookingDetail?.pick_up_time,
                  bookingDetail?.timezone
                )}
              </span>
              <span className="mt-1.5 text-lg font-semibold line-clamp-1">
                {bookingDetail?.custom_pick_up_address}
              </span>
              <ButtonPrimary
                className="mt-1.5"
                onClick={() => {
                  const pickUpLat = bookingDetail?.pick_up_location?.latitude;
                  const pickUpLng = bookingDetail?.pick_up_location?.longitude;

                  if (pickUpLat && pickUpLng) {
                    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${pickUpLat},${pickUpLng}`;
                    window.open(googleMapsUrl, "_blank"); // Opens in a new tab
                  } else {
                    toast.error("Location coordinates are missing");
                  }
                }}
              >
                Get Directions
              </ButtonPrimary>
            </div>
            <div className="gap-2">
              Drop Off
              <span className="mt-1.5 text-lg font-semibold line-clamp-1">
                {formatDateToHumanFirendly(
                  bookingDetail?.drop_off_time,
                  bookingDetail?.timezone
                )}
              </span>
              <span className="mt-1.5 text-lg font-semibold line-clamp-1">
                {bookingDetail?.custom_drop_off_address
                  ? bookingDetail?.custom_drop_off_address
                  : bookingDetail?.custom_pick_up_address}
              </span>
              <ButtonPrimary
                className="mt-1.5"
                onClick={() => {
                  const dropOffLat = bookingDetail?.drop_off_location?.latitude;
                  const dropOffLng =
                    bookingDetail?.drop_off_location?.longitude;

                  if (dropOffLat && dropOffLng) {
                    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${dropOffLat},${dropOffLng}`;
                    window.open(googleMapsUrl, "_blank"); // Opens in a new tab
                  } else {
                    toast.error("Location coordinates are missing");
                  }
                }}
              >
                Get Directions
              </ButtonPrimary>
            </div>
          </div>
        </div>

        <div className="mt-5 grid grid-cols-1 gap-3 rounded-lg  p-4 @md:grid-cols-2 @lg:gap-4 @2xl:gap-5">
          <div>
            <div className="text-lg font-bold">Trip Photos</div>
          </div>
          <div className="col-span-full flex flex-col">
            <div className="flex cursor-pointer flex-row rounded-lg border p-1">
              <Button
                as="span"
                className={`w-[50%] border-0 text-xl leading-[1.8] font-semibold sm:h-[35px] flex justify-center ${
                  isPreTrip === true && "bg-[#F1F4FF]"
                }`}
                onClick={() => {
                  setIsPreTrip(true);
                }}
              >
                Pre Trip
              </Button>
              <Button
                as="span"
                className={`w-[50%] border-0  text-xl leading-[1.8] font-semibold sm:h-[35px] flex justify-center ${
                  !isPreTrip && "bg-[#F1F4FF]"
                }`}
                onClick={() => {
                  setIsPreTrip(false);
                }}
              >
                Post Trip
              </Button>
            </div>
          </div>
          {isPreTrip ? (
            <UploadZone bookingId={bookingId} preTrip={isPreTrip} />
          ) : (
            <UploadZone bookingId={bookingId} preTrip={isPreTrip} />
          )}
        </div>

        <div className="flex flex-col lg:flex-row lg:justify-between gap-0 md:gap-12">
          {" "}
          <div className=" w-full">
            <div className="flex flex-col">
              <div className=" mb-6 md:mb-0">
                <h2 className="text-xl font-bold mb-8">TRIP INFO</h2>
                <div className="mb-4 flex justify-between">
                  <div>
                    <p className="text-gray-800">Agreement</p>
                  </div>
                  <div
                    onClick={openRentalAgreement}
                    className="text-gray-800 cursor-pointer"
                  >
                    <svg
                      width="23"
                      height="26"
                      viewBox="0 0 23 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5253 19.7222C13.3481 19.7222 13.1709 19.6488 13.031 19.4917C12.7605 19.1879 12.7605 18.685 13.031 18.3812L18.1977 12.5774L13.031 6.77361C12.7605 6.4698 12.7605 5.96694 13.031 5.66313C13.3014 5.35932 13.7491 5.35932 14.0195 5.66313L19.6806 12.0222C19.951 12.326 19.951 12.8288 19.6806 13.1327L14.0195 19.4917C13.8797 19.6488 13.7025 19.7222 13.5253 19.7222Z"
                        fill="#90A3BF"
                      />
                    </svg>
                  </div>
                </div>
                <div className="mb-4 flex justify-between items-center">
                  <div>
                    <p className="text-gray-800">Total Bill</p>
                  </div>
                  <span className="text-sm font-medium leading-5 text-left text-[#79808B]">
                    {" "}
                    ${" "}
                    {bookingDetail?.invoice
                      ?.total_price_after_coupon_without_security
                      ? bookingDetail?.invoice
                          ?.total_price_after_coupon_without_security
                      : bookingDetail?.invoice?.total_price_without_security}
                  </span>
                </div>
                {bookingDetail?.invoice?.security_deposit_total !== null && (
                  <div className="mb-4 flex justify-between items-center">
                    <div>
                      <p className="text-gray-800">Total Deposit</p>
                    </div>
                    <span className="text-sm font-medium leading-5 text-left text-[#79808B]">
                      {" "}
                      $ {bookingDetail?.invoice?.security_deposit_total}
                    </span>
                  </div>
                )}

                <div className="mb-4 flex justify-between items-center">
                  <div>
                    <p className="text-gray-800">Get Booking Email</p>
                  </div>
                  <span
                    className="text-green-600 mx-2 cursor-pointer"
                    onClick={() => sendAgreementEmail("booking_confirmation")}
                  >
                    Resend email
                  </span>
                </div>
                <div className="mb-4 flex justify-between items-center">
                  <div>
                    <p className="text-gray-800">Get Agreement</p>
                  </div>
                  <span
                    className="text-green-600 mx-2 cursor-pointer"
                    onClick={() => sendAgreementEmail("rental_agreement")}
                  >
                    Resend email
                  </span>
                </div>
                {/* <div className="mb-4 flex justify-between items-center">
                <div>
                  <p className="text-gray-800">
                    Additional Driver Verification
                  </p>
                  <p className="text-sm font-medium leading-5 text-left text-[#79808B]">
                    Identity verification should be completed before the trip
                    starts
                  </p>
                </div>
                <a
                  href={
                    bookingDetail?.customer?.identity_verified ? undefined : "#"
                  }
                  className={`mx-2 ${
                    bookingDetail?.customer?.identity_verified
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {bookingDetail?.customer?.identity_verified
                    ? "Verified"
                    : "Verification"}
                </a>
              </div> */}
              </div>
              {/* Right Column */}
            </div>

            <div className="w-full mt-4">
              <h2 className="text-xl font-bold my-4">ABOUT THE CAR</h2>
              <div className="mb-4">
                <p className="text-gray-800">{bookingDetail?.fleet.name}</p>
                <a href="#" className="text-gray-800"></a>
              </div>
              <div className="mb-4 flex justify-between">
                <p className="text-gray-800">License plate number</p>
                <span className="font-bold">
                  {bookingDetail?.fleet.plate_number}
                </span>
              </div>
              <div className="mb-4 flex justify-between">
                <p className="text-gray-800">Car color</p>
                <span className="font-bold ">{bookingDetail?.fleet.color}</span>
              </div>
              <div className="mb-4 flex justify-between">
                <p className="text-gray-800">Build</p>
                <span className="font-bold ">{bookingDetail?.fleet.make}</span>
              </div>
              <span className="text-sm text-gray-500 font-bold">
                Reservation ID: {bookingDetail?.id}
              </span>
            </div>
          </div>
          {bookingDetail?.note === null || bookingDetail?.note === "" ? (
            ""
          ) : (
            <div className="w-full">
              <h2 className="text-xl font-bold mb-8">Note</h2>
              <span className="text-gray-800">{bookingDetail?.note}</span>
            </div>
          )}
        </div>

        <Transition appear show={isOpen}>
          <Dialog
            as="div"
            className="relative z-10 focus:outline-none border"
            onClose={close}
          >
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto border border-primary-6000">
              <div className="flex min-h-full items-center justify-center p-4">
                <TransitionChild
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 transform-[scale(95%)]"
                  enterTo="opacity-100 transform-[scale(100%)]"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 transform-[scale(100%)]"
                  leaveTo="opacity-0 transform-[scale(95%)]"
                >
                  <DialogPanel className="w-full max-w-md bg-white rounded-x p-6 backdrop-blur-2xl rounded-xl  border border-primary-6000">
                    <div className="flex justify-between mb-6">
                      <DialogTitle as="h3" className="font-medium text-xl">
                        Modify Trip
                      </DialogTitle>
                      <button onClick={close}>
                        <XMarkIcon className="h-6 w-6" />
                      </button>
                    </div>

                    <ButtonPrimary
                      className="bg-primary-500 w-full mb-5"
                      onClick={() => {
                        modifyBooking(bookingId!);
                      }}
                    >
                      Modify Booking
                    </ButtonPrimary>

                    {/* <ButtonPrimary
                      className=" bg-red-500 w-full"
                      onClick={() => {
                        cancelBooking(bookingId!);
                      }}
                    >
                      {loading ? (
                        <div className="flex justify-center items-center col-span-full">
                          <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                        </div>
                      ) : (
                        "Cancel Booking"
                      )}
                    </ButtonPrimary> */}
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center col-span-full py-96">
          <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
        </div>
      ) : (
        <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
          <main className="container mt-11 mb-24 lg:mb-32 ">
            <div className="max-w-6xl mx-auto">{renderContent()}</div>
          </main>
        </div>
      )}
    </>
  );
};

export default BookingDetailsPage;
