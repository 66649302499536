import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { globalJson } from "global/global_json";

export interface SectionHero4Props {
  className?: string;
}

const SectionHero4: FC<SectionHero4Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-bold text-3xl md:text-4xl xl:text-4xl !leading-[114%] ">
            AM Enterprises – An authentic, convenient car rental experience.
            <span className="text-primary-500">
              Your road, your ride, your way.
            </span>
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400 flex">
            <div className="flex mr-3">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.29004 7.77998V17.51C2.29004 19.41 3.64004 20.19 5.28004 19.25L7.63004 17.91C8.14004 17.62 8.99004 17.59 9.52004 17.86L14.77 20.49C15.3 20.75 16.15 20.73 16.66 20.44L20.99 17.96C21.54 17.64 22 16.86 22 16.22V6.48998C22 4.58998 20.65 3.80998 19.01 4.74998L16.66 6.08998C16.15 6.37998 15.3 6.40998 14.77 6.13998L9.52004 3.51998C8.99004 3.25998 8.14004 3.27998 7.63004 3.56998L3.30004 6.04998C2.74004 6.36998 2.29004 7.14998 2.29004 7.77998Z"
                  stroke="#6B7280"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.56006 4V17"
                  stroke="#6B7280"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.73 6.62V20"
                  stroke="#6B7280"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ml-3">{globalJson.address}</span>
            </div>
            <div className="flex">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.8001 8.67999H6.20005C5.97005 8.67999 5.76005 8.58002 5.62005 8.40002C5.48005 8.22002 5.42005 7.99002 5.47005 7.77002L6.29004 3.84998C6.56004 2.55998 7.15005 1.25 9.45005 1.25H14.5501C16.8601 1.25 17.4501 2.56998 17.7101 3.84998L18.5301 7.77002C18.5801 7.99002 18.5201 8.22002 18.3801 8.40002C18.2401 8.58002 18.0301 8.67999 17.8001 8.67999ZM7.12005 7.17999H16.87L16.2401 4.16998C16.0401 3.20998 15.81 2.76001 14.54 2.76001H9.44005C8.18005 2.76001 7.94006 3.20998 7.74006 4.16998L7.12005 7.17999Z"
                  fill="#6B7280"
                />
                <path
                  d="M17.7698 16.65H16.4098C15.0898 16.65 14.8198 15.83 14.6698 15.39L14.5198 14.95C14.3598 14.47 14.3599 14.4699 13.8499 14.4699H10.1398C9.62984 14.4699 9.62985 14.48 9.46985 14.95L9.31985 15.39C9.16985 15.83 8.89985 16.65 7.57985 16.65H6.21985C5.58985 16.65 4.97984 16.3799 4.55984 15.9199C4.14984 15.4699 3.93984 14.8599 3.99984 14.2499L4.40985 9.83997C4.53985 8.45997 4.98984 7.17993 7.36984 7.17993H16.6198C18.9998 7.17993 19.4498 8.45997 19.5798 9.83997L19.9898 14.2599C20.0498 14.8699 19.8398 15.4799 19.4298 15.9299C19.0098 16.3899 18.3998 16.65 17.7698 16.65ZM16.1798 15.14C16.1998 15.14 16.2798 15.15 16.4098 15.15H17.7698C17.9798 15.15 18.1799 15.0699 18.3199 14.9099C18.4499 14.7699 18.5098 14.58 18.4998 14.39L18.0898 9.96991C18.0098 9.07991 17.9698 8.66992 16.6298 8.66992H7.37985C6.03985 8.66992 5.99984 9.07991 5.91984 9.96991L5.50984 14.39C5.48984 14.58 5.54985 14.7699 5.68985 14.9099C5.82985 15.0599 6.02985 15.15 6.23985 15.15H7.59985C7.79985 15.15 7.85984 15.1199 7.86984 15.1199C7.85984 15.1199 7.88984 14.9999 7.91984 14.9199L8.06985 14.4799C8.25985 13.9199 8.56985 12.9699 10.1598 12.9699H13.8698C15.3198 12.9699 15.6898 13.68 15.9498 14.46L16.0999 14.9099C16.1199 14.9999 16.1498 15.1 16.1798 15.14C16.1798 15.14 16.1698 15.14 16.1798 15.14Z"
                  fill="#6B7280"
                />
                <path
                  d="M6.20021 6.5H5.47021C5.06021 6.5 4.72021 6.16 4.72021 5.75C4.72021 5.34 5.06021 5 5.47021 5H6.20021C6.61021 5 6.95021 5.34 6.95021 5.75C6.95021 6.16 6.61021 6.5 6.20021 6.5Z"
                  fill="#6B7280"
                />
                <path
                  d="M18.5298 6.5H17.7998C17.3898 6.5 17.0498 6.16 17.0498 5.75C17.0498 5.34 17.3898 5 17.7998 5H18.5298C18.9398 5 19.2798 5.34 19.2798 5.75C19.2798 6.16 18.9398 6.5 18.5298 6.5Z"
                  fill="#6B7280"
                />
                <path
                  d="M9.82014 11.5801H7.64014C7.23014 11.5801 6.89014 11.2401 6.89014 10.8301C6.89014 10.4201 7.23014 10.0801 7.64014 10.0801H9.82014C10.2301 10.0801 10.5701 10.4201 10.5701 10.8301C10.5701 11.2401 10.2401 11.5801 9.82014 11.5801Z"
                  fill="#6B7280"
                />
                <path
                  d="M16.3499 11.5801H14.1699C13.7599 11.5801 13.4199 11.2401 13.4199 10.8301C13.4199 10.4201 13.7599 10.0801 14.1699 10.0801H16.3499C16.7599 10.0801 17.0999 10.4201 17.0999 10.8301C17.0999 11.2401 16.7599 11.5801 16.3499 11.5801Z"
                  fill="#6B7280"
                />
                <path
                  d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V17C11.25 16.59 11.59 16.25 12 16.25C12.41 16.25 12.75 16.59 12.75 17V18C12.75 18.41 12.41 18.75 12 18.75Z"
                  fill="#6B7280"
                />
                <path
                  d="M12 22.75C11.59 22.75 11.25 22.41 11.25 22V21C11.25 20.59 11.59 20.25 12 20.25C12.41 20.25 12.75 20.59 12.75 21V22C12.75 22.41 12.41 22.75 12 22.75Z"
                  fill="#6B7280"
                />
                <path
                  d="M2.00009 22.7501C1.94009 22.7501 1.8801 22.7401 1.8201 22.7301C1.4201 22.6301 1.1701 22.2201 1.2701 21.8201L2.2701 17.8201C2.3701 17.4201 2.77009 17.1701 3.18009 17.2701C3.58009 17.3701 3.83009 17.7801 3.73009 18.1801L2.73009 22.1801C2.64009 22.5201 2.34009 22.7501 2.00009 22.7501Z"
                  fill="#6B7280"
                />
                <path
                  d="M22.0002 22.7499C21.6602 22.7499 21.3602 22.5199 21.2702 22.1799L20.2702 18.1799C20.1702 17.7799 20.4102 17.3699 20.8202 17.2699C21.2202 17.1699 21.6302 17.4099 21.7302 17.8199L22.7302 21.8199C22.8302 22.2199 22.5902 22.6299 22.1802 22.7299C22.1202 22.7399 22.0602 22.7499 22.0002 22.7499Z"
                  fill="#6B7280"
                />
              </svg>
              <span className="ml-3">Fleet of 20+ cars</span>
            </div>
          </span>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero4;
